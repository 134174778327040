<template>
  <div class="dashboard">
    <div v-if="classNotify" class="container-fluid pt-4">
      <h2 class="h4 fw-medium">{{ $t('__notifyStation') }}</h2>
      <div class="swiper-container" ref="notifyList">
        <div class="swiper-wrapper">
          <template v-for="notifyType in priorityNotify">
            <div
              v-if="notifyType !== 'mqttStatus'"
              class="swiper-slide"
              :key="'notifyType' + notifyType"
            >
              <div
                class="device-notification shadow-sm"
                :class="
                  notifyType === 'leaveBed' ? 'device-danger' : 'device-warning'
                "
                :style="{ 'min-width': bedWidth ? bedWidth + 'px' : 'auto' }"
              >
                <div class="device-notification-title p-3">
                  <i class="bi bi-exclamation-lg"></i>
                  {{ notify_type_name[notifyType] }}
                </div>
                <div class="device-notification-group">
                  <template v-for="(notify, index) in classNotify[notifyType]">
                    <div
                      :key="notifyType + notify.created_at_epoch + index"
                      class="device-notification-item"
                    >
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <div class="device-no">
                          {{ getDeviceInfo(notify.device_id).bed_number }}
                        </div>
                        <div class="device-notification-time">
                          {{
                            $getTimeZoneDate(
                              notify.created_at_epoch,
                              timezone,
                              'HH:mm:ss'
                            )
                          }}
                        </div>
                      </div>
                      <div class="d-flex justify-content-between">
                        <div class="device-name">
                          {{ getDeviceInfo(notify.device_id).resident_name }}
                        </div>
                        <div
                          class="device-notification-condition"
                          :class="
                            notifyType === 'leaveBed'
                              ? 'text-danger'
                              : 'text-warning'
                          "
                        >
                          {{ notify.type === 'mqttStatus' ? notify_condition_name[notify.message] : notify_condition_name[notify.type] }}
                          <span class="px-1">{{
                            notify.type === 'leaveBed' || notify.type === 'mqttStatus' ? '' : notify.message
                          }}</span>
                          {{ notify_unit[notify.type] }}
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="container-fluid pt-4">
      <div class="align-bottom mb-4">
        <button
          type="button"
          class="fs-2 lh-1 float-end btn btn-link p-0 d-sm-none"
          data-bs-toggle="modal"
          data-bs-target="#bedStatusIcon"
        >
          <i class="bi bi-info-circle-fill"></i>
        </button>
        <h2 class="h4 fw-medium mb-0 pe-3 d-sm-inline-block">
          {{ $t('__bedStatus') }}
        </h2>
        <div class="d-none d-sm-inline-block">
          <span class="pe-3" style="opacity: 0.4">
            <img
              class="align-bottom"
              src="@/assets/images/SIT.svg"
              :alt="$t('__sittingOnTheBed')"
            />
            <span class="ps-1">{{ $t('__sittingOnTheBed') }}</span>
          </span>
          <span class="pe-3">
            <img
              class="align-bottom"
              src="@/assets/images/LYB.svg"
              :alt="$t('__lyingOnTheBed')"
            />
            <span class="ps-1">{{ $t('__lyingOnTheBed') }}</span>
          </span>
          <span class="pe-3">
            <img
              class="align-bottom"
              src="@/assets/images/SLP.svg"
              :alt="$t('__sleep')"
            />
            <span class="ps-1">{{ $t('__sleep') }}</span>
          </span>
          <span class="pe-3">
            <img
              class="align-bottom"
              src="@/assets/images/LVB.svg"
              :alt="$t('__leaveTheBed')"
            />
            <span class="ps-1">{{ $t('__leaveTheBed') }}</span>
          </span>
          <span class="pe-3">
            <img
              class="align-bottom pb-2"
              src="@/assets/images/restless.svg"
              :alt="$t('__restless')"
            />
            <span class="ps-1">{{ $t('__restless') }}</span>
          </span>
          <span class="pe-3">
            <img
              class="align-bottom pb-2"
              src="@/assets/images/rr.svg"
              :alt="$t('__restless')"
            />
            <span class="ps-1">{{ $t('__RR') }}</span>
          </span>
          <span class="pe-3 mb-3">
            <i class="bi bi-circle-fill text-primary"></i>
            <span class="ps-1">{{ $t('__online') }}</span>
          </span>
          <span class="pe-3 mb-3">
            <i class="bi bi-circle-fill text-danger"></i>
            <span class="ps-1">{{ $t('__LVBNotify') }}</span>
          </span>
          <span class="pe-3 mb-3">
            <i class="bi bi-circle-fill text-warning"></i>
            <span class="ps-1">{{ $t('__other') + $t('__notify') }}</span>
          </span>
          <span class="pe-3 mb-3">
            <i class="bi bi-circle-fill text-secondary"></i>
            <span class="ps-1">{{ $t('__offLine') }}</span>
          </span>
        </div>
      </div>
      <div v-if="bedStatus && bedStatus.length" class="row">
        <template v-for="bed in bedStatus">
          <div
            v-if="bed.bed_number && bed.status"
            :key="bed.bed_number"
            class="col-6 col-md-4 col-lg-3 col-xxl-auto mb-4"
            @click="exportDailyReport(bed.bed_number)"
          >
            <div
              :style="{ width: bedWidth ? bedWidth + 'px' : null }"
              class="bed"
              :class="{
                'has-notify': bed.ifNotify,
                'no-wifi': !bed.state.connect_status,
                'notify-danger': bed.notifyType === 'leaveBed',
                'notify-warning':
                  bed.notifyType !== 'leaveBed' &&
                  bed.notifyType !== 'mqttStatus'
              }"
            >
              <div class="row">
                <div
                  class="
                    bed-info
                    col-sm-6
                    fw-medium
                    d-flex
                    flex-column
                    justify-content-between
                  "
                >
                  <div class="bed-no">{{ bed.bed_number }}</div>
                  <div class="bed-name mb-auto">
                    {{ bed.resident_name }}
                  </div>
                  <div class="bed-data d-flex">
                    <div class="d-inline-flex align-items-center pe-3">
                      <img
                        src="@/assets/images/restless.svg"
                        :alt="$t('__restless')"
                      />
                      <span
                        class="ps-1 text-nowrap"
                        :class="{
                          'no-data':
                            bed.state.restless_rate === '-1' ||
                            !bed.state.connect_status
                        }"
                        >{{
                          bed.state.restless_rate === '-1' ||
                          !bed.state.connect_status
                            ? '- -'
                            : bed.state.restless_rate
                        }}</span
                      >
                    </div>
                    <div class="d-inline-flex align-items-center pe-3">
                      <img src="@/assets/images/rr.svg" :alt="$t('__RR')" />
                      <span
                        v-if="
                          Number(bed.state.respiration_rate) < 0 ||
                            !bed.state.connect_status
                        "
                        class="ps-1 text-nowrap no-data"
                      >
                        - -
                      </span>
                      <span v-else class="ps-1 text-nowrap">
                        {{ bed.state.respiration_rate }}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="
                    bed-icon
                    col-sm-6
                    text-end
                    d-flex
                    flex-column
                    justify-content-between
                  "
                >
                  <div class="bed-type">
                    <i
                      v-if="bed.ifNotify"
                      class="bi bi-exclamation-triangle-fill"
                    ></i>
                    <i
                      v-if="
                        bed.state.connect_status && bed.state.lost_connect < 20
                      "
                      class="bi bi-wifi ps-1"
                    ></i>
                    <div
                      v-else-if="
                        bed.state.connect_status && bed.state.lost_connect >= 20
                      "
                      class="ps-1 connecting d-inline-block"
                    >
                      <i class="bi bi-wifi-1"></i>
                      <i class="bi bi-wifi-2"></i>
                      <i class="bi bi-wifi"></i>
                    </div>
                    <i v-else class="bi bi-wifi-off ps-1"></i>
                  </div>
                  <img
                    v-if="
                      bed.state &&
                        bed.state.bed_state &&
                        (bed.state.bed_state === '00' ||
                          bed.state.bed_state === '02' ||
                          bed.state.bed_state === '07' ||
                          bed.state.bed_state === '08' ||
                          bed.state.bed_state === '09' ||
                          bed.state.bed_state === '27')
                    "
                    :src="
                      require(`@/assets/images/${
                        bed.state.bed_state === '09' ||
                        bed.state.bed_state === '27'
                          ? '07'
                          : bed.state.bed_state
                      }-W.svg`)
                    "
                  />
                  <div
                    v-else
                    style="width: 100px; height: 100px;"
                  />
                  <!--
                    00: 睡眠
                    02: 床上坐姿
                    07: 臥床
                    08: 離床
                    09: 翻身動作
                    27: 異常衰弱
                  -->
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-else>
        <p>{{ $t('__noDataToBed') }}</p>
      </div>
    </div>
    <div
      class="modal fade"
      id="bedStatusIcon"
      tabindex="-1"
      aria-labelledby="bedStatusIconLabel"
      aria-hidden="true"
    >
      <div class="modal-sm modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ $t('__descriptionForTheInformationIcon') }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <span class="pe-3 mb-3 d-inline-block" style="opacity: 0.4">
                <img
                  class="align-bottom"
                  src="@/assets/images/SIT.svg"
                  :alt="$t('__sittingOnTheBed')"
                />
                <span class="ps-1">{{ $t('__sittingOnTheBed') }}</span>
              </span>
              <span class="pe-3 mb-3 d-inline-block">
                <img
                  class="align-bottom"
                  src="@/assets/images/LYB.svg"
                  :alt="$t('__lyingOnTheBed')"
                />
                <span class="ps-1">{{ $t('__lyingOnTheBed') }}</span>
              </span>
              <span class="pe-3 mb-3 d-inline-block">
                <img
                  class="align-bottom"
                  src="@/assets/images/SLP.svg"
                  :alt="$t('__sleep')"
                />
                <span class="ps-1">{{ $t('__sleep') }}</span>
              </span>
              <span class="pe-3 mb-3 d-inline-block">
                <img
                  class="align-bottom"
                  src="@/assets/images/LVB.svg"
                  :alt="$t('__leaveTheBed')"
                />
                <span class="ps-1">{{ $t('__leaveTheBed') }}</span>
              </span>
              <span class="pe-3 mb-3 d-inline-block">
                <img
                  class="align-bottom pb-2"
                  src="@/assets/images/restless.svg"
                  :alt="$t('__restless')"
                />
                <span class="ps-1">{{ $t('__restless') }}</span>
              </span>
              <span class="pe-3 mb-3 d-inline-block">
                <img
                  class="align-bottom pb-2"
                  src="@/assets/images/rr.svg"
                  :alt="$t('__restless')"
                />
                <span class="ps-1">{{ $t('__RR') }}</span>
              </span>
            </div>
            <div>
              <span class="pe-3 mb-3">
                <i class="bi bi-circle-fill text-primary"></i>
                <span class="ps-1">{{ $t('__online') }}</span>
              </span>
              <span class="pe-3 mb-3">
                <i class="bi bi-circle-fill text-danger"></i>
                <span class="ps-1">{{ $t('__LVBNotify') }}</span>
              </span>
              <span class="pe-3 mb-3">
                <i class="bi bi-circle-fill text-warning"></i>
                <span class="ps-1">{{ $t('__notifyOther') }}</span>
              </span>
              <span class="pe-3 mb-3">
                <i class="bi bi-circle-fill text-secondary"></i>
                <span class="ps-1">{{ $t('__offLine') }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/swiper-bundle.css'
import Swiper from 'swiper'
import { dashboard } from '@/http/api/dashboard.js'
import { mapMutations, mapState, mapGetters } from 'vuex'
import i18n from '@/lang/lang.js'

export default {
  name: 'Dashboard',
  data () {
    return {
      swiper: null,
      bedStatus: null
    }
  },
  computed: {
    ...mapState([
      'windowWidth',
      'token',
      'notifyList',
      'updateCycle',
      'timezone'
    ]),
    ...mapGetters([
      'sortNotify',
      'notify_type_name',
      'notify_condition_name',
      'notify_unit'
    ]),
    bedWidth: function () {
      const vm = this
      let bedWidth = 0
      if (vm.windowWidth > 1900) {
        bedWidth = vm.windowWidth / 6 - 14 * 2
      } else if (vm.windowWidth > 1400) {
        bedWidth = vm.windowWidth / 5 - 14 * 2
      }
      return parseInt(bedWidth)
    },
    withinAnHourNotify: function () {
      const vm = this
      if (!vm.sortNotify) return ''
      var withinAnHourNotify = []
      vm.sortNotify.forEach(notify => {
        if (
          Math.floor(
            new Date() -
              new Date(this.$getUTCmilliseconds(notify.created_at_epoch))
          ) <=
          1000 * 60 * 60
        ) {
          withinAnHourNotify.push(notify)
        }
      })
      return withinAnHourNotify
    },
    classNotify: function () {
      const vm = this
      if (!vm.withinAnHourNotify || !vm.withinAnHourNotify.length) return ''
      var newNotifications = {}
      // 分類好通知類型
      vm.withinAnHourNotify.forEach(ele => {
        if (
          ele.type === 'leaveTimeout' ||
          ele.type === 'leaveBed' ||
          ele.type === 'siteTimeout'
        ) {
          if (!newNotifications.leaveBed) {
            newNotifications.leaveBed = []
          }
          newNotifications.leaveBed.push(ele)
        } else if (
          ele.type === 'respirationRateGreater' ||
          ele.type === 'respirationRateLess'
        ) {
          if (!newNotifications.respirationRate) {
            newNotifications.respirationRate = []
          }
          newNotifications.respirationRate.push(ele)
        } else {
          if (!newNotifications[ele.type]) {
            newNotifications[ele.type] = []
          }
          newNotifications[ele.type].push(ele)
        }
      })
      return newNotifications
    },
    priorityNotify: function () {
      const vm = this
      if (!vm.withinAnHourNotify || !vm.withinAnHourNotify.length) return ''
      var arrayNotificationsType = []
      // 通知類型時間優先順序列出來
      vm.withinAnHourNotify.forEach(ele => {
        if (
          ele.type === 'leaveTimeout' ||
          ele.type === 'leaveBed' ||
          ele.type === 'siteTimeout'
        ) {
          arrayNotificationsType.push('leaveBed')
        } else if (
          ele.type === 'respirationRateGreater' ||
          ele.type === 'respirationRateLess'
        ) {
          arrayNotificationsType.push('respirationRate')
        } else {
          arrayNotificationsType.push(ele.type)
        }
      })
      // 刪除重複的通知類型
      arrayNotificationsType = [...new Set(arrayNotificationsType)]
      return arrayNotificationsType
    }
  },
  watch: {
    notifyList () {
      this.getBedStatus()
    },
    classNotify () {
      this.$nextTick(function () {
        this.initSwiper()
      })
    }
  },
  methods: {
    ...mapMutations(['Loading', 'Loaded', 'AlertsInfo', 'Alerted']),
    getBedStatus () {
      const vm = this
      // vm.Loading()
      dashboard
        .getDeviceState(vm.token)
        .then(res => {
          if (res.status <= 201) {
            const data = res.data.data
            data.sort((a, b) => (a.bed_number > b.bed_number ? 1 : -1))
            vm.bedStatus = data

            vm.isNotify()
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
          }
          // vm.Loaded()
        })
        .catch(err => {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: err
          })
          vm.Alerted()
          vm.Loaded()
        })
    },
    initSwiper () {
      const vm = this
      if (vm.swiper) vm.swiper.destroy()
      vm.swiper = new Swiper('.swiper-container', {
        slidesPerView: 'auto',
        spaceBetween: 20
      })
    },
    exportDailyReport (bedNo) {
      const vm = this
      vm.$router.push({
        name: 'Graphical',
        query: {
          bed_no: bedNo,
          report_type: 'daily',
          date: vm.$getTimeZoneDate(new Date(), vm.timezone, 'YYYY-MM-DD'),
          data_type: ['restless', 'RR', 'bedStatus'],
          notify_type: [
            'restless',
            'RR',
            'LYB',
            'LVB',
            'leaveTheBedTimeOut',
            'SITTimeOut'
          ],
          from: 'Dashboard'
        }
      })
    },
    getDeviceInfo (id) {
      const vm = this
      let deviceInfo = null
      vm.notifyList.forEach(notify => {
        if (notify.id === id) {
          deviceInfo = notify
        }
      })
      return deviceInfo
    },
    isNotify () {
      const vm = this
      vm.bedStatus.forEach(bed => {
        vm.notifyList.forEach(notify => {
          if (notify.id === bed.id) {
            const nowState = bed.state.bed_state
            vm.$set(bed, 'ifNotify', false)
            vm.$set(bed, 'notifyType', null)
            notify.message.forEach(message => {
              if (
                Math.floor(
                  new Date() -
                    new Date(vm.$getUTCmilliseconds(message.created_at_epoch))
                ) <=
                1000 * 60 * 3
              ) {
                /* nowState
                  00: 睡眠
                  02: 床上坐姿
                  07: 臥床
                  08: 離床
                  09: 翻身動作
                  27: 異常衰弱
                */
                /* notify leaveBed 有以下三種
                  leaveTimeout
                  leaveBed
                  siteTimeout
                */
                if (
                  message.type === 'leaveTimeout' ||
                  message.type === 'leaveBed' ||
                  message.type === 'siteTimeout'
                ) {
                  bed.notifyType = 'leaveBed'
                } else if (
                  message.type === 'respirationRateGreater' ||
                  message.type === 'respirationRateLess'
                ) {
                  bed.notifyType = message.type
                } else {
                  bed.notifyType = message.type
                }
                if (bed.notifyType === 'leaveBed' && nowState !== '08') {
                  bed.ifNotify = false
                  bed.notifyType = null
                } else {
                  bed.ifNotify = true
                }
              }
            })
            setTimeout(() => {
              bed.ifNotify = false
              bed.notifyType = null
            }, 1000 * 60 * 3)
          }
        })
      })
    }
  },
  created () {},
  mounted () {
    const vm = this
    if (vm.notifyList) {
      vm.getBedStatus()
    }
    if (vm.notifyList && vm.notifyList.length) {
      vm.$nextTick(function () {
        vm.initSwiper()
      })
    }
    const timer = setInterval(() => {
      if (vm.userInfo) {
        vm.getBedStatus()
      }
    }, vm.updateCycle)
    vm.$once('hook:beforeDestroy', () => {
      clearInterval(timer)
    })
  }
}
</script>

<style lang="scss">
.connecting {
  position: relative;
  width: 20px;
  height: 20px;
  i {
    animation-name: connecting;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  i:nth-child(2) {
    animation-delay: 0.4s;
  }
  i:nth-child(3) {
    animation-delay: 0.8s;
  }
}
.dashboard {
  .swiper-slide {
    width: auto;
  }
}
.device {
  &-no {
    font-size: 14px;
    color: $dark;
  }
  &-name {
    font-size: 20px;
    font-weight: 500;
    padding-right: 20px;
    @include media-breakpoint-down(sm) {
      font-weight: 500;
    }
  }
  &-notification {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding-top: 56%;
      z-index: -1;
    }
    &.device-danger {
      &:after {
        background: $linear-gradient-danger;
      }
    }
    &.device-warning {
      &:after {
        background: $linear-gradient-warning;
      }
    }
    &-group {
      max-height: 260px;
      overflow-y: auto;
    }
    &-title {
      font-size: 24px;
      font-weight: 500;
      color: $white;
    }
    &-item {
      background-color: $white;
      padding: 0.5rem 1.5rem;
      border-top: 1px solid #d7d7d7;
      &:first-child {
        border-radius: 54px 0 0 0;
        padding: 1.5rem;
        border-top: none;
        .device-name {
          font-size: 26px;
        }
        .d-flex:first-child {
          margin-bottom: 0.3rem;
        }
      }
    }
    &-time {
      font-size: 14px;
      color: $dark;
      font-weight: 500;
      @include media-breakpoint-down(sm) {
        font-weight: 500;
      }
    }
    &-condition {
      font-size: 14px;
      width: 100px;
      text-align: right;
      font-weight: 500;
    }
  }
}
.bed {
  background: $linear-gradient;
  position: relative;
  padding: 10px;
  min-width: 245px;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: $box-shadow-sm;
  transition: box-shadow 0.3s;
  overflow: hidden;
  @include media-breakpoint-down(sm) {
    width: 100%;
    min-width: auto;
    height: auto;
  }
  &:hover {
    box-shadow: $box-shadow-lg;
  }
  &.has-notify {
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    &.notify-danger {
      animation-name: notifyDanger;
      background: $linear-gradient-danger;
      box-shadow: 0 0 15px 2px #ff8d8d !important;
    }
    &.notify-warning {
      animation-name: notifyWarning;
      background: $linear-gradient-warning;
      box-shadow: 0 0 15px 2px #ffcf72 !important;
    }
  }
  &.no-wifi {
    background: $linear-gradient-gray;
  }
  > div {
    position: relative;
    height: 100%;
    z-index: 1;
  }
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/images/bed-background.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 0;
    @include media-breakpoint-down(sm) {
      width: 203%;
      padding-top: 230%;
      height: 0;
      bottom: -56vw;
      background-image: url('~@/assets/images/bed-background-V.svg');
      background-position: bottom;
    }
  }
  &::after {
    @include media-breakpoint-down(sm) {
      content: '';
      background-color: #fff;
      width: 100%;
      height: calc(100% - 36vw);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }
  &-name {
    font-size: 26px;
    min-width: 126px;
    z-index: 1;
  }
  &-data,
  &-no {
    font-size: 16px;
    color: $dark;
  }
  &-type {
    font-size: 20px;
    color: $white;
    @include media-breakpoint-down(sm) {
      padding-top: 20px;
    }
  }
  &-icon {
    img {
      width: 100px;
      margin: auto;
      @include media-breakpoint-down(sm) {
        width: 80%;
        margin-bottom: -10px;
        margin-top: -28px;
      }
    }
  }
  .no-data {
    animation-name: noData;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
}

@keyframes connecting {
  100% {
    opacity: 1;
  }
  67% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  0% {
    opacity: 0;
  }
}

@keyframes noData {
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}

@keyframes notifyDanger {
  100% {
    outline: 10px solid rgba(255, 141, 141, 0);
  }
  50% {
    outline: 10px solid rgba(255, 141, 141, 51%);
  }
  0% {
    outline: 10px solid rgba(255, 141, 141, 0);
  }
}
@keyframes notifyWarning {
  100% {
    outline: 10px solid rgba(255, 141, 141, 0);
  }
  50% {
    outline: 10px solid rgba(#ffcf72, 51%);
  }
  0% {
    outline: 10px solid rgba(255, 141, 141, 0);
  }
}
</style>
